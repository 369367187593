import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
//import MarketPlacePng from './img/Web 1920 – 1-min.png';
import MarketPlacePng from './img/Marketplace@2x-min.png';
import Structure3DPng from './img/Structure3D@2x-min.png';
import BIMViewerPng from './img/BIMViewer@2x-min.png';
// import ConcreteSectionLogo from './img/ConcreteSection.svg';
// import SteelSectionLogo from './img/SteelSection.svg';
import {
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
  } from 'reactstrap';
import InterWaveSvg from './img/InterWave.svg';

const items = [
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 2',
    caption: 'Slide 2'
  },
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 3',
    caption: 'Slide 3'
  }
];


export default class FunctionalitySection extends Component{
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render(){
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} />
          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    const { link } = this.props;
    return(
      <div className="section">
        <img className="imgWave" src={InterWaveSvg} alt="Wave" />        
        <Container className="functionnalityContainer">
        {/* <iframe src="https://giphy.com/embed/8L0OMY9Kj1N5u6wdkL" width="480" height="270" frameBorder="0" className="giphy-embed" allowFullScreen>
        </iframe> */}
          <h2 className="title">
            <FormattedMessage 
              id="functionnality.title"
              defaultMessage="A Market Place for civil engineering applications"
            />
          </h2>
          <Row>
            <Col xs={12}>
              <Slide bottom>
                <img
                  className="marketplaceImg" 
                  src={MarketPlacePng}
                  // alt={alt} 
                />
              </Slide>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Fade left>
                <img
                  className="app left" 
                  src={Structure3DPng}
                  // alt={alt} 
                />
              </Fade>
            </Col>
            <Col xs={6}>
              <Fade right>
                <img
                  className="app right" 
                  src={BIMViewerPng}
                  // alt={alt} 
                />
              </Fade>
            </Col>
          </Row>
          {/* <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel> */}
          <p className="lead">
            <FormattedMessage 
              id="functionnality.text.1" 
              values={{
                strong1: <strong>
                  <FormattedMessage id="functionnality.text.1.strong.1" />
                </strong>,
                strong2: <strong>
                <FormattedMessage id="functionnality.text.1.strong.2" />
              </strong>,
              }}
            />
          </p>
          <p className="lead">
            <FormattedMessage 
              id="functionnality.text.2" 
              values={{
                strong1: <strong>
                  <FormattedMessage id="functionnality.text.2.strong.1" />
                </strong>,
                strong2: <strong>
                <FormattedMessage id="functionnality.text.2.strong.2" />
              </strong>,
              }}
            />
          </p>
          {/* <Row>
            <Button
              href={link}
              className="negativeButton"
            >
              <FormattedMessage id="guaranty.button" />
            </Button>
          </Row> */}
        </Container>
      </div>            
    )
  }
}