import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import * as typeformEmbed from '@typeform/embed';
import InterWaveSvg from './img/InterWave.svg';
import WaveSvg from './img/Wave.svg';
import ESITCParisPng from './img/ESITCParis1.png';
import ESITCMetzPng from './img/ESITCMetz.png';
import SolideoJpg from './img/Solideo.jpg';
import datBIMJpg from './img/datBIM.png';
import BatisseurDAvenirPng from './img/BatisseurDAvenir.png';
import { FormattedMessage } from 'react-intl';

export default class ReferenceSection extends Component {
  render() {
    const { link } = this.props;
    return (
    <div className="referenceSection">
      <img className="imgWave" src={InterWaveSvg} alt="Wave" />
      <Container className="referenceContainer">
        <Row>
          <Col md={4}>
            <a href="https://www.esitc-paris.fr/fr" target="_blank">
              <FormattedMessage id="reference.esitc.paris">
                {
                  alt =>  (
                    <img 
                      className="img" 
                      src={ESITCParisPng}
                      alt={alt}
                    />
                  )
                }
              </FormattedMessage>
            </a>
          </Col>
          <Col md={4}>
            <a href="http://batisseursdavenir.fr/" target="_blank">
              <FormattedMessage id="reference.batisseurdavenir">
                {
                  alt =>  (
                    <img 
                      className="img" 
                      src={BatisseurDAvenirPng}
                      alt={alt} 
                    />
                  )
                }
              </FormattedMessage>
            </a>
          </Col>
          <Col md={4}>
            <a href="http://www.esitc-metz.com/" target="_blank">
              <FormattedMessage id="reference.esitc.metz">
                {
                  alt =>  (
                    <img 
                      className="img" 
                      src={ESITCMetzPng}
                      alt={alt}
                    />
                  )
                }
              </FormattedMessage>
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={2}></Col>
          <Col md={4}>
            <a href="https://www.ouvrages-olympiques.fr/" target="_blank">
              <FormattedMessage id="reference.solideo">
                {
                  alt =>  (
                    <img 
                      className="img" 
                      src={SolideoJpg}
                      alt={alt}
                    />
                  )
                }
              </FormattedMessage>
            </a>
          </Col>
          <Col md={4}>
            <a href="https://www.datbim.com/" target="_blank">
              <FormattedMessage id="reference.datbim">
                {
                  alt =>  (
                    <img 
                      className="img" 
                      src={datBIMJpg}
                      alt={alt}
                    />
                  )
                }
              </FormattedMessage>
            </a>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
    </div>
    );
  }
}