import React, { Component } from 'react';
import * as typeformEmbed from '@typeform/embed';
import Fade from 'react-reveal/Fade';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Container,
    Nav,
    Row,
    NavItem,
    Button,
    Col,
    Label,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Media
  } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import HeaderImageJpg from './img/HeaderImage-1-min.jpg';
import MacScreenPng from './img/Mac screen@2x-min1.png';
// import LandingPage from './img/HeaderLandingPage.svg';
// import LandingPage from './img/HeaderBackGround.svg';
// import LandingPage from './img/LandingPageBackGround.svg';
// import LandingPage from './img/HeaderWithWave.svg';
// import MacScreen from './img/MacScreen.svg';
// import IphoneScreen from './img/IphoneScreen.svg';
// import LandingPage from './img/LandingPage.jpg';
import TriDymeIcon from './img/TriDymeIcon.png';
import AskForDemoButton from '../../../../../components/Button/AskForDemo';


export default class Header extends Component{
  render(){
    const { link } = this.props;
    return(
      <div className="header" style={{backgroundImage: `url(${HeaderImageJpg})`}}>
        <Container fluid>
          <Row className="headerBackground">
            <Col lg={5}>
              <div className="intro-message">
                <Row>
                  <Fade left>
                    <h1 id="title">
                      <FormattedMessage
                        id="header.title"
                        defaultMessage="Online structural analysis software"
                      />
                    </h1>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing"/>
                </Row>                
                <Row>
                  <Fade left>
                    <h5 id="subtitle">
                      <FormattedMessage
                        id="header.subtitle.1"
                        defaultMessage="No need to install any software, available everywhere, "  
                      />
                      <strong>
                        <FormattedMessage
                          id="header.subtitle.2"
                          defaultMessage="easy and powerful. "  
                        />                      
                      </strong>
                      <FormattedMessage
                        id="header.subtitle.3"
                        defaultMessage="With TriAzur, make your calculations "  
                      />
                      <strong>
                        <FormattedMessage
                          id="header.subtitle.4"
                          defaultMessage="directly in the browser."  
                        />
                      </strong>
                    </h5>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing"/>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Fade left>
                      {/* <AskForDemoButton 
                        id="landingPage.presentation.button.demo"
                        defaultMessage="Ask for a demo"
                        className="mainButton"
                      /> */}
                      <Button 
                        className="mainButton"
                        href={`${link}/signup`} 
                        color="primary" 
                        size="lg">
                        <FormattedMessage
                          id="landingPage.presentation.button.demo"
                          defaultMessage="Try it now"
                        />
                      </Button>
                    </Fade>
                  </Col>
                  <Col lg={6}>

                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={7}>
              <Fade right>
                <div className="header-image">
                  <img src={MacScreenPng} alt />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}