import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import Structure3D from '../scenes/LandingPages/Structure3D';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
	en: {
		title: `TriDyme | Structure3D`,
		pageTitle: "Structure3D",
		description: "TriDyme Structure3D presentation"
	},
	fr: {
		title: `TriDyme | Structure 3D`,
		pageTitle:  "Structure 3D",
		description: "TriDyme présentation de Structure3D"
	}
};

const Structure3DPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Structure3D />
  </Layout>
)

Structure3DPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default Structure3DPage;