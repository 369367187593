import React, { Component } from 'react';
import Header from './scenes/Header';
import FonctionalitySection from './scenes/FonctionalitySection';
import ReferenceSection from './scenes/ReferenceSection';
import Structure3DSection from './scenes/Structure3DSection';
import SectionsSection from './scenes/SectionsSection';
import BimViewerSection from './scenes/BimViewerSection';

const {
  REACT_APP_URL
} = process.env;

export default class Home extends Component{
  render(){
		const linkToApp = "https://app.tridyme.com";
    return(
      <div className="home">
        <Header link={linkToApp}/>
        <FonctionalitySection link={linkToApp}/>
        <Structure3DSection link={linkToApp}/>
        <SectionsSection link={linkToApp}/>
        <BimViewerSection link={linkToApp}/>
        <ReferenceSection link={linkToApp}/>
      </div>
    )
  }
}
