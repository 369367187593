import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  Media,
  Label
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import InterWaveSvg from './img/InterWave.svg';
import Structure3DIconSvg from './img/Structure3DIcon.svg';
import Structure3DSollicitationsJpg from './img/Structure3DLoads.jpg';
import AskForDemoButton from '../../../../../components/Button/AskForDemo';

export default class Structure3DSection extends Component{
  render(){
    const { link } = this.props;
    return(
      <div className="section">
        <img className="imgWave" src={InterWaveSvg} alt="No Installation, No Maintenance" />        
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <Fade left cascade>
                  <Media className="app">
                    <Media left href="#">
                      <FormattedMessage id="structure3D_alt">
                        {
                          alt =>  (
                            <img
                              className="icon" 
                              src={Structure3DIconSvg}
                              alt={alt} 
                            />
                          )
                        }
                      </FormattedMessage>
                      {/* <Media object data-src="holder.js/64x64" alt="Structure 3D" /> */}
                    </Media>
                    <Media body>
                      <Media heading>
                        <h2 className="label">
                          <FormattedMessage
                            id="structure3D_title"
                            defaultMessage="Structure 3D"
                          />                         
                        </h2>
                      </Media>
                      <Label className="subTitle">
                        Structure
                      </Label>
                    </Media>
                  </Media>
                </Fade>
              </Row>
              <Row>
                <Fade left>
                  <p className="lead">
                    <FormattedMessage 
                      id="structure3D_text" 
                      values={{
                        strong: <strong>
                          <FormattedMessage id="structure3D_strong" />
                        </strong>
                      }}
                    />
                  </p>
                </Fade>
              </Row>
              <Row>
                <Fade left>
                  <Button
                      href={link}
                      className="negativeButton"
                    >
                      <FormattedMessage id="landingPage.presentation.button.demo" />
                    </Button>
                  {/* <AskForDemoButton
                    id="landingPage.presentation.button.demo"
                    defaultMessage="Ask for a demo"
                    className="negativeButton"
                  /> */}
                </Fade>
              </Row>
            </Col>
            <Col md={6}>
              <Fade right>
                <img className="imgPresentation" src={Structure3DSollicitationsJpg} alt="Structure Analysis" />        
              </Fade>       
            </Col>
          </Row>
        </Container>    
      </div>       
    )
  }
}
