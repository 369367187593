import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  Media,
  Label
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import InterWaveSvg from './img/InterWave.svg';
import SectionsIconSvg from './img/ConcreteSectionIcon.svg';
import ConcreteSectionStressJpg from './img/ConcreteSectionGeometry.jpg';
import AskForDemoButton from '../../../../../components/Button/AskForDemo';


export default class SectionsSection extends Component{
  render(){
    const { link } = this.props;
    return(
      <div className="section"> 
        <img className="imgWave" src={InterWaveSvg} alt="Sections" />        
        <Container>
          <Row>
            <Col md={6}>
              <div className="spacing">
                <Fade left>
                  <img className="imgPresentation" src={ConcreteSectionStressJpg} alt="Sections" />        
                </Fade>
              </div>
            </Col>
            <Col md={6}>
              <Row>
              <Fade right cascade>
                  <Media className="app">
                    <Media left href="#">
                      <FormattedMessage id="section_alt">
                        {
                          alt =>  (
                            <img
                              className="icon" 
                              src={SectionsIconSvg}
                              alt={alt} 
                            />
                          )
                        }
                      </FormattedMessage>
                      {/* <Media object data-src="holder.js/64x64" alt="Structure 3D" /> */}
                    </Media>
                    <Media body>
                      <Media heading>
                        <h2 className="label">
                          <FormattedMessage
                            id="section_title"
                            defaultMessage="Sections"
                          />                         
                        </h2>
                      </Media>
                      <Label className="subTitle">
                        Structure
                      </Label>
                    </Media>
                  </Media>
                </Fade>
              </Row>
              <Row>
                <Fade right>
                  <p className="lead">
                    <FormattedMessage 
                      id="section_text" 
                      values={{
                        strong: <strong>
                          <FormattedMessage id="section_strong" />
                        </strong>
                      }}
                    />
                  </p>
                </Fade>
              </Row>
              <Row>
                <Fade right>
                  <Button
                    href={link}
                    className="negativeButton"
                  >
                    <FormattedMessage id="landingPage.presentation.button.demo" />
                  </Button>
                  
                  {/* <AskForDemoButton 
                    id="landingPage.presentation.button.demo"
                    defaultMessage="Ask for a demo"
                    className="negativeButton"
                  /> */}
                </Fade>
              </Row>
            </Col>
          </Row>
        </Container>    
      </div>       
    )
  }
}
